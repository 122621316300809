<script>
    import Driver from 'driver.js';
    import 'driver.js/dist/driver.min.css';
    import {mapState} from 'vuex'


    const defaultFlag = {Database:0,CMS:0,Runtimes:1,Ecom:0,CRM:0,Education: 0,'Message Queue':0,Operations:0}
    export default {
        name: "guidance",
        computed:{
            ...mapState('application', ['appInfo']),
        },
        data() {
            return {
                show:false
            }
        },
        methods:{
            getStorage(){
                let ShowGuidance =  localStorage.getItem('ShowGuidance')
                if(!ShowGuidance){
                    localStorage.setItem(`ShowGuidance`,JSON.stringify(defaultFlag))
                    ShowGuidance = defaultFlag
                }else{
                    ShowGuidance = Object.assign({},defaultFlag,JSON.parse(ShowGuidance))
                }
                return ShowGuidance
            },
            showDriver(){
                let ShowGuidance =  this.getStorage()
                let category = this.appInfo.category
                if (!ShowGuidance[category]) {
                    this.initDriver()
                    ShowGuidance[category] = 1
                    localStorage.setItem('ShowGuidance',JSON.stringify(ShowGuidance))
                }
            },
            initDriver() {
                this.driver = this.driver || new Driver({
                    opacity: 0.2,
                    allowClose: true,
                    overlayClickNext: true,
                    showButtons: false,
                    position:'right',
                })
                setTimeout(() => {
                    this.driver.defineSteps([{
                            element: '#guidance',
                            popover: {
                                title: 'Guidance',
                                description: `Need help getting started? Click Guidance for more details.`,
                                showButtons: false,
                            }
                        }]);
                    this.driver.start();
                },1000)
            },
            // ...mapGetters('application',['getGuidanceSteps'])
        },
        created() {
            this.$root.$on('showGuidance', () => {
                this.showDriver()
            })
        },
        beforeDestroy() {
            this.$root.$off('showGuidance')
            if(this.driver){
                this.driver.reset(true)
            }
        }
    }
</script>
