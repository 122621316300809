<template>
        <HorLayout>
            <Breadcrumbs v-if="isInit" slot="title"></Breadcrumbs>
            <SideRoute slot="nav" :menus="menus" v-if="isInit"></SideRoute>
            <SideMenu slot="left" :menus="menus"></SideMenu>
            <Guidance ref="guid"></Guidance>
            <div slot="right" v-if="isInit">
                <transition
                        name="slide"
                        mode="out-in">
                    <router-view></router-view>
                </transition>
            </div>
            <Spin v-if="showLoading" :mask="true" style="z-index: 1">
                <v-subheader class="panel-title " >{{loadingDescription}}</v-subheader>
                <template v-if="!progress || !progress.process || !progress.process.steps">
                    <v-progress-circular
                            :size="60"
                            color="blue lighten-3"
                            indeterminate
                    ></v-progress-circular>
                    <div class="loading-text mt-4">it may take some time to finish.</div>
                </template>
                <template v-else>
                    <v-progress-circular
                            :size="60"
                            indeterminate
                            color="blue lighten-3">
                        {{ `${progress.process.steps_failure_num+progress.process.steps_success_num}/${progress.process.steps_total_num}` }}
                    </v-progress-circular>

                    <ol style="text-align: left;margin-top: 30px;list-style: none">
                        <li v-for="(step ,i) in progress.process.steps" :key="i" style="margin-top: 10px">
                            <span class="step-index" :class="{active : step.status==='success'}">{{i+1}}</span>
                            {{` ${step.description} `}}
                        </li>
                    </ol>
                </template>
            </Spin>
        </HorLayout>
</template>

<script>
    import HorLayout from '@/components/layout/HorLayout.vue'
    import SideMenu from '@/components/SideMenu/index.vue'
    import SideRoute from '@/components/SideRoute.vue'
    import Spin from '@/components/Spin'
    import Breadcrumbs from '@/components/Breadcrumbs.vue'
    import Guidance from './_source/Guidance.vue'

    import {mapActions, mapState ,mapGetters} from 'vuex'
    //   :value="progress.process.steps.reduce((t,o) => o.status==='success'?(t+o.weights):t, 0)"


    export default {
        name: "ApplicationDetail",
        components: {HorLayout, SideMenu, SideRoute ,Spin,Breadcrumbs,Guidance},
        data() {
            return {
                loadingDescription : '',
                showLoading: false,
                isInit: false,
                progress : {},
            }
        },
        computed: {
            topLoading() {
                return this.$store.state.topLoading
            },
            menus() {
                if (!this.isInit) {
                    return [];
                }
                return this.getCategoryMenus(this.appInfo)
            },
            ...mapGetters('application', ['getCategoryMenus','getServiceId']),
            ...mapState('application', ['appInfo', 'topLoading']),
        },
        methods: {
            initInfo() {
                this.toggleLoading(true)
                this.getAppDetailById({appId: this.appId, isReset: true}).then(() => {
                    this.getAppStats().then(() => {
                        this.isInit = true
                        this.toggleLoading(false)
                        this.tick()
                    })
                }).catch(() => {
                    this.toggleLoading(false)
                    this.$message.error({
                        content: 'Oops, it failed to get your application information by ID, please contact us at support@cloudclusters.io for this issue.',
                        duration: 0,
                        closable: true
                    })
                })
            },
            toggleLoading(flag) {
                this.$store.commit('toggleLoading', flag)
            },
            tick(){
                this.getMaskStatus().then((o) => {
                    this.progress = o.progress
                    this.loadingDescription = o.description
                    let  return_code = o.return_code
                    this.showLoading = return_code !== 0
                    if(this.timer === 'stop'){
                        return
                    }
                    if(this.showLoading){
                        this.timer =  setTimeout(() => {
                            this.tick()
                        },5000)
                    }else{
                        this.clearTick()
                        this.$emit('mask-over')
                    }
                }).catch(() => {
                    this.timer =  setTimeout(() => {
                        this.tick()
                    },5000)
                })
            },
            clearTick(timer='init'){
                clearTimeout(this.timer)
                this.progress = {}
                this.timer= timer
            },
            ...mapActions('application', ['getAppDetailById','getAppStats','getMaskStatus'])
        },
        created() {
            this.timer='init'
            this.appId = this.$route.params.appId
            this.initInfo()
            this.$SDK.track({
                pageName : 'ApplicationDetail',
                productName : this.appInfo.product_name
            })
        },
        beforeDestroy(){
            this.clearTick('stop')
        }
    }
</script>

<style lang="scss">
    .step-index{
        height: 24px;
        display: inline-block;
        line-height: 24px;
        text-align: center;
        width: 24px;
        border-radius: 12px;
        background-color: #ccc;
        color: #999;
        &.active{
            color: #fff;
            background-color: #3F51B5;
        }
    }
    .guidance {
        position: fixed;
        top: 48px;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 999;
        background:transparent;
    }


</style>
