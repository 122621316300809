<template>
    <v-breadcrumbs :items="items" divider=">" class="pt-0">
        <template v-slot:item="{item}">
            <router-link :to="item.path" >{{ item.text }}
            </router-link>
        </template>
    </v-breadcrumbs>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: "Breadcrumbs",
        computed: {
            items(){
                return [{
                        path: this.first.path ,
                        text: this.first.name
                    }].concat([{
                    path: this.$route.path,
                    text: this.appInfo.name
                }])
            },
            ...mapState('application', ['appInfo']),
        },
        created(){
            this.first = this.$router.options.routes.find((item) => {
                return item.name.toLowerCase() === 'applications'
            })
        }
    }
</script>

