var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "HorLayout",
    [
      _vm.isInit
        ? _c("Breadcrumbs", { attrs: { slot: "title" }, slot: "title" })
        : _vm._e(),
      _vm.isInit
        ? _c("SideRoute", {
            attrs: { slot: "nav", menus: _vm.menus },
            slot: "nav"
          })
        : _vm._e(),
      _c("SideMenu", {
        attrs: { slot: "left", menus: _vm.menus },
        slot: "left"
      }),
      _c("Guidance", { ref: "guid" }),
      _vm.isInit
        ? _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "transition",
                { attrs: { name: "slide", mode: "out-in" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showLoading
        ? _c(
            "Spin",
            { staticStyle: { "z-index": "1" }, attrs: { mask: true } },
            [
              _c("v-subheader", { staticClass: "panel-title " }, [
                _vm._v(_vm._s(_vm.loadingDescription))
              ]),
              !_vm.progress ||
              !_vm.progress.process ||
              !_vm.progress.process.steps
                ? [
                    _c("v-progress-circular", {
                      attrs: {
                        size: 60,
                        color: "blue lighten-3",
                        indeterminate: ""
                      }
                    }),
                    _c("div", { staticClass: "loading-text mt-4" }, [
                      _vm._v("it may take some time to finish.")
                    ])
                  ]
                : [
                    _c(
                      "v-progress-circular",
                      {
                        attrs: {
                          size: 60,
                          indeterminate: "",
                          color: "blue lighten-3"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.progress.process.steps_failure_num +
                                _vm.progress.process.steps_success_num +
                                "/" +
                                _vm.progress.process.steps_total_num
                            ) +
                            "\n            "
                        )
                      ]
                    ),
                    _c(
                      "ol",
                      {
                        staticStyle: {
                          "text-align": "left",
                          "margin-top": "30px",
                          "list-style": "none"
                        }
                      },
                      _vm._l(_vm.progress.process.steps, function(step, i) {
                        return _c(
                          "li",
                          { key: i, staticStyle: { "margin-top": "10px" } },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "step-index",
                                class: { active: step.status === "success" }
                              },
                              [_vm._v(_vm._s(i + 1))]
                            ),
                            _vm._v(
                              "\n                    " +
                                _vm._s(" " + step.description + " ") +
                                "\n                "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }